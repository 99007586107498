import CompanyArticle from "../company-article/company-article";
import Modal from "../modal/modal";
import SteelworkCompanyArticle from "../steelwork-company-article/steelwork-company-article";

class CompaniesFilter {
  private el: Element;
  private modal: Modal;
  private types: NodeListOf<Element>;
  private items: NodeListOf<Element>;

  constructor(el: Element) {
    this.el = el;
    this.modal = Modal.getInstance();
    this.types = this.el.querySelectorAll(".js-companies-filter-types button");
    this.items = this.el.querySelectorAll(".js-companies-filter-items-item");

    this.types.forEach((type) => {
      type.addEventListener("click", (ev: Event) => {
        const el = ev.currentTarget as HTMLElement;
        const type = el.dataset.target ?? "all";
        this.unSelectAllButtons();
        el.setAttribute("aria-selected", "true");
        this.filterItems(type);
      });
    });

    this.items.forEach((item: Element) => {
      item.addEventListener("click", (ev: Event) => {
        const el = ev.currentTarget as HTMLElement;
        const objType = el.dataset.objType;
        console.log(objType);
        const target = `#${objType}-${el.id}`;
        this.modal.setContent(
          target,
          () => {
            if (objType === "company") {
              new CompanyArticle(this.modal.getHTMLElement(), this.modal);
            } else if (objType == "steelwork-company") {
              new SteelworkCompanyArticle(
                this.modal.getHTMLElement(),
                this.modal
              );
            }
          },
          objType
        );
        this.modal.open();
      });
    });
  }

  private filterItems(type: string) {
    this.items.forEach((item: Element) => {
      if (type === "all") {
        item.classList.remove("companies-filter__items-item--hidden");
      } else if (item.dataset.types === type) {
        item.classList.remove("companies-filter__items-item--hidden");
      } else {
        item.classList.add("companies-filter__items-item--hidden");
      }
    });
  }

  private unSelectAllButtons() {
    this.types.forEach((type) => {
      type.setAttribute("aria-selected", "false");
    });
  }
}

const initCompaniesFilters = () => {
  const filters = document.querySelectorAll(".js-companies-filter");

  if (filters) {
    for (let filter of filters) {
      new CompaniesFilter(filter);
    }
  }
};

initCompaniesFilters();

export default CompaniesFilter;
